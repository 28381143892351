import TableHeaderCell from '../components/table-header-cell';
import withCellData from '../services/hocs/withCellData';
import ReservationDateCell from '../components/reservation-date-cell';
import ReservationTimeCell from '../components/reservation-time-cell';
import React from 'react';
import SvgCalendar from 'src/assets/svg/SvgCalendar.svg';
import SvgClock from 'src/assets/svg/SvgClock.svg';

export const RES_SORT_BY = {
    ID: 'ID',
    DATE: 'DATE',
};
export const RES_SORT_ORDER = {
    ASC: 'ASC',
    DESC: 'DESC',
};

export const RESERVATION_STATUS = {
    CANCELLED_BY: {
        ADMIN: 'cancelled_by_admin',
        GUEST: 'cancelled_by_guest',
        TIMEOUT: 'cancelled_by_timeout',
        HOST: 'cancelled_by_host',
    },
    CONFIRMED: 'confirmed',
    WAITING: 'waiting',
    REQ_CONFIRM: 'requires_payment_confirmation',
    ACTIVE: 'active',
    CALCULATED: {
        PAST: 'past',
        UPCOMING: 'upcoming',
        ACTIVE: 'active',
        CANCELED: 'canceled',
    },
};

export const CHANGE_RATE_THRESHOLD = 40;
export const MAX_MINUTES_MODIFY = 30;
export const LOW_RATING_THRESHOLD = 4;
export const LOW_FENCING_THRESHOLD = 90;
export const LOW_CLEAN_THRESHOLD = 80;
export const LOW_PHOTOS_THRESHOLD = 4;
export const INACTIVE_SPOT_THRESHOLD = 30;

export const CHECKBOX_FIELDS = {
    active: {
        label: 'Active',
        name: 'active',
    },
    upcoming: {
        label: 'Upcoming',
        name: 'upcoming',
    },
    declined: {
        label: 'Declined',
        name: 'declined',
    },
    past: {
        label: 'Past',
        name: 'past',
    },
};

export const CHECKBOX_CONFIG = [CHECKBOX_FIELDS.active, CHECKBOX_FIELDS.upcoming, CHECKBOX_FIELDS.declined, CHECKBOX_FIELDS.past];

export const SELECT_VALUES = {
    NOT_SELECTED: 'not_selected',
    TOMORROW: 'tomorrow',
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    LAST_7_DAYS: 'last-7-days',
    CUSTOM: 'custom',
};

export const SELECT_OPTIONS = [
    {
        value: SELECT_VALUES.NOT_SELECTED,
        label: 'All time',
    },
    {
        value: SELECT_VALUES.TOMORROW,
        label: 'Tomorrow',
    },
    {
        value: SELECT_VALUES.TODAY,
        label: 'Today',
    },
    {
        value: SELECT_VALUES.YESTERDAY,
        label: 'Yesterday',
    },
    {
        value: SELECT_VALUES.LAST_7_DAYS,
        label: 'Last 7 days',
    },
    {
        value: SELECT_VALUES.CUSTOM,
        label: 'Custom',
    },
];

export const RESERVATION_TABLE = {
    DATE_COLUMN: {
        Header: <TableHeaderCell title="Date" icon={<SvgCalendar />} />,
        Cell: withCellData(ReservationDateCell),
        id: 'date',
        accessor: ({ date }) => ({ date }),
    },
    TIME_COLUMN: {
        Header: <TableHeaderCell title="Time" icon={<SvgClock />} />,
        Cell: withCellData(ReservationTimeCell),
        id: 'time',
        accessor: ({ date, length, spot }) => ({ date, length, spot }),
    },
};

export const CONFIG = {
    TAB: {
        MAP: 'map',
        STREET_VIEW: 'street_view',
    },
    MODAL: {
        REVIEW_INFO: 'review-info',
        REPORT: 'report',
        WRITE_REVIEW: 'write-review',
    },
};

export const RESERVATION_TAB = {
    UPCOMING: 'UPCOMING',
    PAST: 'PAST',
    CANCELED: 'CANCELED',
    FILTERED: 'FILTERED',
};

export const RESERVATION_STATUS_TEXT = {
    cancelled_by_host: 'Cancelled by Host',
    cancelled_by_guest: 'Cancelled by Guest',
    cancelled_by_admin: 'Cancelled by Admin',
    cancelled_by_timeout: 'Cancelled by Timeout',
    confirmed: 'Confirmed',
    visiting: 'Visiting now',
    requires_payment_confirmation: 'Requires payment confirmation',
};

export const RESERVATION_REMINDERS = [
    'If you have any dogs, put them away from the spot and, if possible, not audible/visible for safety and to avoid distractions.',
    'Remember by default, guests generally expect privacy during visits. If you need to meet first time guests, be sure to use caution when approaching, since many dogs are sensitive to strangers.',
    'Make sure the spot is clean and free of hazards.',
    'If you provide amenities, make sure they are accessible to guests.',
    'If the guest bought extras, make sure to provide them.',
];

export const RESERVATION_TYPE = {
    BOOK: 'BOOK',
    CALENDAR: 'CALENDAR',
};
