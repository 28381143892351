import React from 'react';
import loadable from '@loadable/component';
import { showSize } from '../../helpers/common';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import { showDistanceFromYou } from '../../helpers/spot';
import { SIGN_UP_SRC, SPOT_ENCLOSURE } from '../../consts/spot.consts';
import RenderSpotMembership from './render-spot-membership';
import RenderSpotSniffpass from './render-spot-sniffpass';
import RequestMorePhotos from '../../components/request-more-photos';
import SpotBreadcrumbs from '../../components/spot-breadcrumbs';
import RenderHostInfo from './render-host-info';
import RenderSubInfo from './render-sub-info';
import SniffButton from '../../components/sniff-button';
import PictureSet from '../../components/picture-set';
import SvgLocation from 'src/assets/svg/SvgLocation.svg';
import SvgFenced from 'src/assets/svg/SvgFenced.svg';
import SvgPhoto from 'src/assets/svg/SvgPhoto.svg';
import SvgRuler from 'src/assets/svg/SvgRuler.svg';

const SpotFavFab = loadable(() => import('../../components/SpotFavFab'));
const SpotFavFabSSR = loadable(() => import('../../components/SpotFavFab/fav-ssr'));
const SpotShareButton = loadable(() => import('../../components/spot-share-button'));
const SpotShareButtonSSR = loadable(() => import('../../components/spot-share-button/share-ssr'));
const DesktopPhotoGallery = loadable(() => import('./desktop-photo-gallery'));

const RenderHeadDesktop = ({
    listBread,
    spot,
    goToHost,
    goToReviews,
    toggleDesktopPhotoGallery,
    desktopPhotoCount,
    desktopPhotoGallery,
    handleNextDesktopGallery,
    handleBackDesktopGallery,
    handleSniffpassBadge,
    handleMembershipBadge,
    SSR,
    useState,
    useCallback,
    isMobile,
    favSpots,
    useMutateFavorites,
    userDetails,
    useGetShareLink,
    setDesktopPhotoCount,
}) => {
    const creatorName = `${spot?.host?.nameAndInitial} | Sniffspot` || undefined;
    const canShowGallery = userDetails.id && desktopPhotoGallery;

    return (
        <div className="desktop-header d-md-block d-none">
            <SpotBreadcrumbs {...{ SSR, listBread }} />
            <h1 className="snif-l2 snif-semibold mb-2">{spot.title}</h1>
            <div className="header-info">
                <div className="info-left">
                    <div className="spot-location">
                        <SvgLocation />
                        {spot.city ? <span>{spot.city}</span> : null}
                        {spot.state ? <span>, {spot.state}</span> : null}
                        {showDistanceFromYou(spot.distanceFrom, userDetails)}
                    </div>
                    <RenderHostInfo spot={spot} handleGoTo={goToHost} useState={useState} />
                    {spot.offerSniffpass && <RenderSpotSniffpass {...{ handleSniffpassBadge }} />}
                    {spot.offerMembership && spot.activeMembership && <RenderSpotMembership {...{ handleMembershipBadge }} />}
                </div>
                <div className="spot-core-action">
                    {SSR ? (
                        favSpots === undefined ? (
                            ''
                        ) : (
                            <SpotFavFabSSR
                                src={SIGN_UP_SRC.FAVORITE_SPOT_WEB}
                                {...{ spot, favSpots, userDetails, useState, useCallback, useMutateFavorites }}
                            />
                        )
                    ) : (
                        <SpotFavFab src={SIGN_UP_SRC.FAVORITE_SPOT_WEB} spot={spot} />
                    )}

                    {SSR ? (
                        <SpotShareButtonSSR
                            media={spot.spotPhotos && spot.spotPhotos.length !== 0 && spot.spotPhotos[0].imageAws}
                            {...{ spot, useState, isMobile, useGetShareLink }}
                        />
                    ) : (
                        <SpotShareButton media={spot.spotPhotos && spot.spotPhotos.length !== 0 && spot.spotPhotos[0].imageAws} {...{ spot }} />
                    )}
                </div>
            </div>
            <div className="spot-header-detail">
                <RenderSubInfo spot={spot} handleGoTo={goToReviews} isMobileSSR={isMobile} />
                <div className="spot-core-detail snif-s1">
                    {spot.enclosureType && (
                        <div className="head-info-box">
                            <SvgFenced />
                            {SPOT_ENCLOSURE[spot.enclosureType?.toUpperCase()]}
                        </div>
                    )}
                    {spot.size && (
                        <div className="head-info-box">
                            <SvgRuler />
                            {showSize(spot.size, spot.sizeUnits)}
                        </div>
                    )}
                </div>
            </div>
            {spot.spotPhotos && spot.spotPhotos.length > 2 ? (
                <>
                    <div className="spot-photos-block">
                        <div className="img-big">
                            <div className="desktop-photo" onClick={() => toggleDesktopPhotoGallery(0)}>
                                <PictureSet
                                    imgs={ImageHelper.getUrlFromOptions(spot.spotPhotos[0].imageAws, `full-fit-in/${IMG_RESIZES.x768}`)}
                                    className="photos-item"
                                    method="eager"
                                    fetchpriority="high"
                                    alt={`${spot.title} - ${spot.city}, ${spot.state}`}
                                    SSR={SSR}
                                    useState={useState}
                                    width={800}
                                    height={532}
                                    creatorName={creatorName}
                                />
                            </div>
                        </div>
                        <div className="img-sml">
                            {spot.spotPhotos[1] ? (
                                <div className="desktop-photo" onClick={() => toggleDesktopPhotoGallery(1)}>
                                    <PictureSet
                                        imgs={ImageHelper.getUrlFromOptions(spot.spotPhotos[1].imageAws, `full-fit-in/${IMG_RESIZES.x768}`)}
                                        className="photos-item"
                                        method="eager"
                                        fetchpriority="high"
                                        alt={`${spot.title} - ${spot.city}, ${spot.state}`}
                                        SSR={SSR}
                                        useState={useState}
                                        width={500}
                                        height={320}
                                        creatorName={creatorName}
                                    />
                                </div>
                            ) : (
                                <div className="desktop-photo placeholder-photo">
                                    <SvgPhoto />
                                </div>
                            )}

                            {spot.spotPhotos[2] ? (
                                <div className="desktop-photo" onClick={() => toggleDesktopPhotoGallery(2)}>
                                    <PictureSet
                                        imgs={ImageHelper.getUrlFromOptions(spot.spotPhotos[2].imageAws, `full-fit-in/${IMG_RESIZES.x768}`)}
                                        className="photos-item"
                                        method="eager"
                                        fetchpriority="high"
                                        alt={`${spot.title} - ${spot.city}, ${spot.state}`}
                                        SSR={SSR}
                                        useState={useState}
                                        width={500}
                                        height={320}
                                        creatorName={creatorName}
                                    />
                                </div>
                            ) : (
                                <div className="desktop-photo placeholder-photo">
                                    <SvgPhoto />
                                </div>
                            )}
                        </div>
                        <SniffButton color="secondary" className="show-all-photo" onClick={() => toggleDesktopPhotoGallery()}>
                            Show all photos
                        </SniffButton>
                    </div>
                </>
            ) : (
                <>
                    <div className="spot-photos-block">
                        <div className="img-big full-img">
                            {spot.spotPhotos && spot.spotPhotos.length > 0 ? (
                                <div className="desktop-photo" onClick={() => toggleDesktopPhotoGallery(0)}>
                                    <PictureSet
                                        imgs={ImageHelper.getUrlFromOptions(spot.spotPhotos[0].imageAws, `full-fit-in/${IMG_RESIZES.x1536}`)}
                                        className="photos-item"
                                        method="eager"
                                        fetchpriority="high"
                                        alt={`${spot.title} - ${spot.city}, ${spot.state}`}
                                        SSR={SSR}
                                        useState={useState}
                                        width={800}
                                        height={532}
                                        creatorName={creatorName}
                                    />
                                </div>
                            ) : (
                                <RequestMorePhotos id={spot.id} />
                            )}
                        </div>
                        {spot.spotPhotos && spot.spotPhotos.length > 1 && (
                            <SniffButton color="secondary" className="show-all-photo" onClick={() => toggleDesktopPhotoGallery()}>
                                Show all photos
                            </SniffButton>
                        )}
                    </div>
                </>
            )}
            <hr />
            <DesktopPhotoGallery
                photos={spot.spotPhotos}
                currentPhoto={desktopPhotoCount}
                isOpen={canShowGallery}
                toggle={toggleDesktopPhotoGallery}
                hostId={spot.host?.id}
                spotId={spot.id}
                handleNext={handleNextDesktopGallery}
                handleBack={handleBackDesktopGallery}
                title={spot.title}
                state={spot.state}
                city={spot.city}
                useState={useState}
                setDesktopPhotoCount={setDesktopPhotoCount}
            />
        </div>
    );
};

export default RenderHeadDesktop;
